<script lang="jsx">

export default {
  name: 'ColorV2PromotionText',
  functional: true,
  props: {
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    largeImageMode: {
      type: Boolean,
      default: false
    },
    promotionText: {
      type: String,
      default: ''
    },
    showGoodsDesc: {
      type: Boolean,
      default: false
    },
    promotionLink: {
      type: Function,
      default: () => {}
    },
    promotionEntryExpose: {
      type: Function,
      default: () => {}
    },
    promotionEntryClick: {
      type: Function,
      default: () => {}
    },
  },
  render(h, { props }) {
    const { 
      promotionText, 
      showGoodsDesc,
      supportLargeImageList,
      largeImageMode,
      promotionLink,
      promotionEntryExpose,
      promotionEntryClick,
    } = props
    if (!!promotionText) {
      return (
        <div
          class={['goods-color__promotion-link', {
            'goods-color__promotion-link_desc': showGoodsDesc,
            'goods-color__promotion-link_large': supportLargeImageList && largeImageMode,
          }]}
        >
          <div
            class="goods-color__promotion-link-box"
            vOn:click={() => { promotionLink() }}
            {...{ directives: [
              { name: 'expose', value: promotionEntryExpose() }, 
              { name: 'tap', value: promotionEntryClick() }] 
            }}
          >
            <div class="suiiconfont sui_icon_activity_12px goods-color__promotion-link-icon"></div>
            <div
              class="goods-color__promotion-link-text"
            >
              { promotionText }
            </div>
            <div class="goods-color__promotion-link-right">
              {'>'}
            </div>
          </div>
      
        </div>
      )
    }

    return null
  }
}

</script>
