import Vue from 'vue'
let instance = null

export default {
  show(params) {
    if (instance) {
      instance.show(params)
      return
    }

    try {
      import(/* webpackChunkName: "FindShade" */ './FindShade.vue').then(com => {
        const FindShadeComp = Vue.extend(com.default)
        instance = new FindShadeComp({
          el: document.createElement('div'),
        })
        document.body.appendChild(instance.$el)
        instance.show(params)
      })
    } catch (error) {
      console.log(error)
    }

  }
}
