<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ImageBox } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'ColorV2Desc',
  components: {
    ImageBox,
    Icon
  },
  props: {
    goodsDesc: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    goodsId: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      default: () => ({})
    },
    goodsMainAttPicInfo: {
      type: Object,
      default: null
    },
    maxRetry: {
      type: Number,
      default: 30
    },
  },
  data() {
    return {
      showFold: false,
      foldState: true,
      retryCount: 0,
      retryTimer: 0,
      mainPicBigImgShow: false
    }
  },
  computed: {
    mainPicAnalysisData() {
      let { from, goodsId } = this
      return {
        location: from,
        goods_id: goodsId,
      }
    }
  },
  watch: {
    goodsDesc() {
      this.showFold = false
      this.$nextTick(() => {
        clearTimeout(this.retryTimer)
        this.refreshFold()
      })
    },
    goodsId: {
      handler() {
        this.exposeMainPic()
      },
      immediate: true
    },
  },
  mounted() {
    this.refreshFold(true)
  },
  beforeUnmount() {
    clearTimeout(this.retryTimer)
  },
  methods: {
    // 更新折叠状态
    refreshFold(firstTime) {
      const context = this.$refs?.context
      if (!context) {
        return
      }
      const contextHeight = context.clientHeight
      if (contextHeight < 1 && this.retryCount < this.maxRetry) {
        console.log('Waiting for ColorDesc render...')
        this.retryCount = this.retryCount + 1
        this.retryTimer = setTimeout(() => {
          this.refreshFold(firstTime)
        }, 1e2)
        return
      }

      if (contextHeight > 54) {
        this.showFold = true
        if (firstTime) {
          this.foldState = true
        }
      } else {
        this.showFold = false
      }
      this.retryCount = 0
      this.postAnalysis()
    },
    onUnfold() {
      this.foldState = false
      daEventCenter.triggerNotice({
        daId: '1-6-1-103',
        extraData: {
          fold_tp: 'unfold'
        },
      })
    },
    onFold() {
      this.foldState = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-103',
        extraData: {
          fold_tp: 'fold'
        },
      })
    },
    postAnalysis() {
      if (!this.goodsDesc) {
        return
      }
      this.$nextTick(() => {
        const { showFold } = this
        daEventCenter.triggerNotice({
          daId: '1-6-1-102',
          extraData: {
            fold_tp: showFold ? 'fold' : '0'
          },
        })
      })
    },
    clickMainPicOpenBigImg() {
      this.mainPicBigImgShow = !this.mainPicBigImgShow
    },
    exposeMainPic() {
      this.$nextTick(() => { 
        if (!this.goodsMainAttPicInfo) return
        daEventCenter.triggerNotice({
          daId: '1-6-1-173',
          extraData: {
            main_attr: 1, // 主销售属性曝光需要增加该字段
            ...(this.mainPicAnalysisData || {}),
          },
        })
      })
    }
  },
  render() {
    const { 
      goodsDesc, 
      language,
      goodsMainAttPicInfo,
      mainPicAnalysisData
    } = this
    if (!goodsDesc && !goodsMainAttPicInfo) return null
    
    const renderFold = () => {
      return (
        <p
          ref="context"
          class={['context-box', { 'fold': this.showFold && this.foldState }]}
        >
          <span
            class="context"
            domPropsInnerHTML={goodsDesc}
          ></span>
          {
            this.showFold && this.foldState ? (
              <span
                class="unfold-btn"
                vOn:click={() => { this.onUnfold() }}>
            ...<span class="btn-text">{ language.SHEIN_KEY_PWA_18777 }</span>
              </span>     
            ) : null
          }   
        </p>
      )
    }

    const renderFoldBtn = () => {
      if (!this.showFold) {
        return null
      }

      if (!this.foldState) {
        return (
          <p
            class="fold-btn-box"
          >
            <span
              class="fold-btn"
              vOn:click={() => { this.onFold() }}
            >{ language.SHEIN_KEY_PWA_18779 }</span>
          </p>
        )
      }

      return null
    }

    const renderMainAttrPic = () => {
      if (!goodsMainAttPicInfo) return null
      let { textDetail, attr_value, attr_image } = goodsMainAttPicInfo || {}
      return <div class="skc-pic">
        <p class="skc-pic__text">
          <span class="skc-pic__text-detail">{ textDetail }</span>
          <span class="skc-pic__text-name">{ attr_value }</span>
        </p>
        <div
          class="skc-pic__img"
          vOn:click={this.clickMainPicOpenBigImg}
          {...{ directives: [{ name: 'tap', value: { id: '1-6-1-174', data: mainPicAnalysisData } }] }}
        >
          <img src={attr_image} />
        </div>
        <ImageBox append-to-body={true} visible={this.mainPicBigImgShow}>
          <div class="skc-pic__big" vOn:click={this.clickMainPicOpenBigImg}>
            <div class="skc-pic__big-close">
              <Icon name="sui_icon_close_18px" size="24px" color="#000" />
            </div>
            <img src={attr_image} />
            <p class="skc-pic__big-text">{ attr_value }</p>
          </div>
        </ImageBox>
      </div>
    }
  
    return (
      <div class="goods-color__desc-box">
        {renderFold()}
        {renderFoldBtn()}
        {renderMainAttrPic()}
      </div>
    )
  }
}

</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.goods-color__desc-box {
  padding: .213333rem .32rem;
  background-color: #fafafa;
  margin-bottom: .32rem;

  .context-box {
    font-size: .32rem;
    line-height: .426667rem;
    color: #767676;
    position: relative;
    &.fold {
      height: 1.28rem;
      overflow: hidden;
    }

    .context {
      display: inline;
      overflow-wrap: break-word;
    }
    .unfold-btn {
      display: inline-block;
      padding-left: .426667rem;
      position: absolute;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, rgb(250, 250, 250) 30%, rgb(250, 250, 250) 100%);
      color: #222;
      .btn-text {
        color: #2d68a8;
      }
    }
  }

  .fold-btn-box {
    font-size: .32rem;
    line-height: .426667rem;
    color: #2d68a8;
  }

  .skc-pic {
    padding: 4px 0;
  }
  .skc-pic__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  .skc-pic__text-detail {
    color: #666;
  }
  .skc-pic__text-name {
    color: #000;
  }
  .skc-pic__img {
    margin-top: .2133rem;
  }
  .skc-pic__img > img {
    max-width: 100%;
    max-height: 72px;
  }

}

.skc-pic__big {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.skc-pic__big-close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  width: .8533rem;
  height: .8533rem;
  border-radius: .8533rem;
  position: absolute;
  top: .16rem;
  left: .2133rem;
}
.skc-pic__big-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFF;
  position: absolute;
  width: 8.72rem;
  max-width: 8.72rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.6133rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
