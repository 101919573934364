<script lang="jsx">

export default {
  name: 'CropImageContainerJsx',
  functional: true,
  props: {
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#F6F6F6'
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    imgProps: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  render(h, { props }) {
    const { imgSrc, containerBackground, fixedRatio, imgProps } = props
    
    const isSquareSrc = imgSrc.includes('_square')
    // 商品中心原图
    const originImgSrc = () => {
      // 商品中心原图(不带后缀)
      return imgSrc.replace(/(_square)|(_squfix)/ig, '')
    }
    // 容器比例
    const containerRatio = () => {
      if (fixedRatio === '1-1') return 1 / 1
      if (fixedRatio === '3-4') return 4 / 3
      if (fixedRatio === '4-5') return 5 / 4
      if (fixedRatio === '5-6') return 6 / 5
      if (fixedRatio === '13-16') return 16 / 13
      if (isSquareSrc) return 1 / 1
      return 4 / 3
    }

    return (
      <div
        class="crop-image-container"
        style={{
          backgroundColor: `${containerBackground}`,
          paddingBottom: `calc(${containerRatio().toFixed(3)} * 100%)`
        }}
        data-before-crop-src={imgSrc}
      >
        <img
          id={imgProps?.id}
          class={['crop-image-container__img', imgProps?.class]}
          data-src={originImgSrc()}
          src={imgProps?.src || originImgSrc()}
          data-design-width={imgProps?.dataDesignWidth}
          data-exp={imgProps?.dataExp}
        />
      </div>
    )
  }
}
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
  }
}
</style>
